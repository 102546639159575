/**
=========================================================
* NextJS Material Dashboard 2 PRO - v2.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/nextjs-material-dashboard-pro
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
 =========================================================
* The above copyright notice and this permission notice shall be
 included in all copies or substantial portions of the Software. */

// MUI
import Drawer from '@mui/material/Drawer';
import { styled } from '@mui/material/styles';

export default styled(Drawer)(({ theme, ownerState }) => ({
  '& .MuiDrawer-paper': {
    height: '100vh',
    margin: 0,
    padding: `0 ${theme.functions.pxToRem(10)}`,
    borderRadius: 0,
    boxShadow: theme.boxShadows.lg,
    overflowY: 'auto',
    ...(ownerState.openConfigurator ? ({
      width: 360, // configuratorWidth
      left: 'initial',
      right: 0,
      transition: theme.transitions.create('right', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.short,
      }),
    }) : ({
      left: 'initial',
      right: theme.functions.pxToRem(-350),
      transition: theme.transitions.create('all', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.short,
      }),
    })),
  },
}));
